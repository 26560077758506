<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :colon="false" @submit="handleSubmit" :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="项目名">
                            <a-input v-decorator="[
                                'projectName',
                                {
                                    initialValue: detail.projectName,
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" placeholder="请输入" style="width: 100%"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="类型">
                        <a-select
                            v-decorator="[
                            'type',
                            {
                                initialValue: detail.type,
                                rules: [
                                {
                                    required: true,
                                    message: '请选择！',
                                },
                                ],
                            },
                            ]"
                        >
                            <a-select-option
                            v-for="item in typeList"
                            :key="item.value"
                            :value="item.value"
                            >{{ item.name }}</a-select-option
                            >
                        </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="招标人">
                            <a-input v-decorator="[
                                'bidUserName',
                                {
                                    initialValue: detail.bidUserName,
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" placeholder="请输入" style="width: 100%"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item>
                        <span class="required" slot="label">经办人</span>
                        <EmployeeSelector @change="(arr) => {
                                            if (arr.length > 0) {
                                            selectedUser = arr[0];
                                            } else {
                                            selectedUser = {};
                                            }
                                        }
                                        " :value="selectedUser.name ? [selectedUser] : []">
                            <a-button block style="text-align: left">
                            <div v-if="selectedUser.name">
                                {{ selectedUser.name }}
                            </div>
                            <div v-else>&nbsp;</div>
                            </a-button>
                        </EmployeeSelector>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="部门">
                            <a-select
                                show-search
                                :filter-option="$selectFilterOption"
                                v-decorator="[
                                'deptId',
                                { initialValue: detail.deptId,rules: [{ required: true, message: '请选择！' }] },
                                ]"
                                style="width: 100%"
                                placeholder=""
                            >
                                <a-select-option
                                v-for="item in organizationList"
                                :key="item.id"
                                :value="item.id"
                                >{{ item.uniqueName }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="待回款金额">
                            <a-input type="number" v-decorator="[
                                'collectedAmount',
                                {
                                    initialValue: detail.collectedAmount,
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" placeholder="请输入" style="width: 100%"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="付款金额">
                            <a-input type="number" v-decorator="[
                                'payAmount',
                                {
                                    initialValue: detail.payAmount,
                                    rules: [
                                        { required: true, message: '请输入！' },
                                    ],
                                },
                            ]" placeholder="请输入" style="width: 100%"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="付款日期">
                            <a-date-picker
                                style="width: 100%"
                                placeholder=""
                                v-decorator="[
                                'payDate',
                                {
                                    initialValue: detail.payDate,
                                    rules: [
                                    {
                                        required: true,
                                        message: '请选择！',
                                    },
                                    ],
                                },
                                ]"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <div class="footer center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                        <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>

<script>
import moment from "moment";
import EmployeeSelector from "@/components/employee-selector";
import organization from "@/mixins/organization";
import { mapGetters } from 'vuex'
import request from "@/api/request";
function getDetail(id) {
  return request({
    url: "/project-service/dsBond/detail/" + id,
  });
}
function add(data) {
    return request({
        url: "project-service/dsBond/update",
        method: "post",
        data
    });
}
export default {
    mixins: [organization],
    name: 'moneyEdit',
    
    components: {
        EmployeeSelector
    },

    data() {
        return {
            detail: {},
            selectedUser: {},
            form: this.$form.createForm(this),
            loading: false,
        };
    },

    mounted(){
        const { query } = this.$route;
        const { id } = query || {};
        getDetail(id).then(res => {
            this.detail = res;
            this.selectedUser.id = res.operatorId;
            this.selectedUser.name = res.operatorName;
        });
    },
    computed: {
        ...mapGetters("setting", ["findDataDict"]),

        typeList() {
            return this.findDataDict("ds_bond_type");
        },

    },

    methods: {
        handleSubmit(e) {
            e.preventDefault();

            this.form.validateFields((err, values) => {
                if (!err) {
                    if (!this.selectedUser.name) {
                        this.$message.error("请选择经办人");
                        return;
                    }
                    this.loading = true;
                    const { query } = this.$route;
                    const { id } = query || {};
                    add({
                        ...values,
                        id:id,
                        operatorId: this.selectedUser.id,
                        operatorName: this.selectedUser.name,
                        deptName: this.organizationList.filter(v=>v.id == values.deptId)[0].uniqueName,
                        payDate: moment(values.payDate).format("YYYY-MM-DD"),
                    })
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    this.$message.error("请填写必填项目");
                }
            });
        }
    }
};
</script>


<style lang="less" scoped>
.title {
    font-size: 14px;
    color: #1890ff;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-left: 8px;
}

.footer {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>