var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"项目名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'projectName',
                            {
                                initialValue: _vm.detail.projectName,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'projectName',\n                            {\n                                initialValue: detail.projectName,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'type',
                        {
                            initialValue: _vm.detail.type,
                            rules: [
                            {
                                required: true,
                                message: '请选择！',
                            },
                            ],
                        },
                        ]),expression:"[\n                        'type',\n                        {\n                            initialValue: detail.type,\n                            rules: [\n                            {\n                                required: true,\n                                message: '请选择！',\n                            },\n                            ],\n                        },\n                        ]"}]},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"招标人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'bidUserName',
                            {
                                initialValue: _vm.detail.bidUserName,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'bidUserName',\n                            {\n                                initialValue: detail.bidUserName,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("经办人")]),_c('EmployeeSelector',{attrs:{"value":_vm.selectedUser.name ? [_vm.selectedUser] : []},on:{"change":(arr) => {
                                        if (arr.length > 0) {
                                        _vm.selectedUser = arr[0];
                                        } else {
                                        _vm.selectedUser = {};
                                        }
                                    }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.selectedUser.name)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedUser.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'deptId',
                            { initialValue: _vm.detail.deptId,rules: [{ required: true, message: '请选择！' }] },
                            ]),expression:"[\n                            'deptId',\n                            { initialValue: detail.deptId,rules: [{ required: true, message: '请选择！' }] },\n                            ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"placeholder":""}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"待回款金额"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'collectedAmount',
                            {
                                initialValue: _vm.detail.collectedAmount,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'collectedAmount',\n                            {\n                                initialValue: detail.collectedAmount,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"type":"number","placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"付款金额"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'payAmount',
                            {
                                initialValue: _vm.detail.payAmount,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'payAmount',\n                            {\n                                initialValue: detail.payAmount,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"type":"number","placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"付款日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'payDate',
                            {
                                initialValue: _vm.detail.payDate,
                                rules: [
                                {
                                    required: true,
                                    message: '请选择！',
                                },
                                ],
                            },
                            ]),expression:"[\n                            'payDate',\n                            {\n                                initialValue: detail.payDate,\n                                rules: [\n                                {\n                                    required: true,\n                                    message: '请选择！',\n                                },\n                                ],\n                            },\n                            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1)],1),_c('div',{staticClass:"footer center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }